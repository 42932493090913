import { Validators, FormGroup, FormControl } from '@angular/forms';

export interface ClientModel {
    id: number;
    name: string;
    description: string;
}

export class ClientFormBuilder {

    clientModel : ClientModel = {
        id: null,
        name: '',
        description: ''
    }

    clientForm: FormGroup = new FormGroup({
        name: new FormControl(this.clientModel.name, [Validators.required]),
        description: new FormControl(this.clientModel.description, [Validators.required]),
    });

    constructor() {
        this.clientForm.valueChanges.subscribe(val => {
            this.clientModel.name = val.name;
            this.clientModel.description = val.description;
        });
    }

    setModelVals(res: ClientModel) {
        this.clientForm.controls.name.setValue(res.name);
        this.clientForm.controls.description.setValue(res.description);
    }
}
