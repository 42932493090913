import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { JasperParameterModel } from 'src/app/shared/models/jasper-parameter-model';
import { HttpRequestService } from '..';

@Injectable({
  providedIn: 'root'
})
export class ManageReportService {
  private stateUrl = 'api/v1/jasper';
  public manageParametersDetails = new BehaviorSubject<any>(null);

  manageReportFieldDetails = this.manageParametersDetails.asObservable();


  constructor(private httpRequest: HttpRequestService) { }
  addJasperReportData(formData: FormData): Observable<any> {
    return this.httpRequest.filePost(this.stateUrl + '/add', formData);
  }
  getReportDetails(): Observable<JasperParameterModel[]> {
    return this.httpRequest.get(this.stateUrl + '/reports');
  }
  deleteReportById(Id: number): Observable<number> {
    return this.httpRequest.delete(this.stateUrl + '/delete/' + Id);
  }
  routeToReportIds(jasperParameterModel: JasperParameterModel) {
    this.manageParametersDetails.next(jasperParameterModel);
  }
}
export interface ManageReportSection {
  id?: number;
  name?: String;
  description?: String;
  folderPath?: String;
  moduleName?: String;
  parameter?: JasperReportSectionParameter[]
}
export interface JasperReportSectionParameter {
  name?: string,
  jasperReportId?: String,
  type?: string

}
