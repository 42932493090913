import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { IntegrationTypeModel } from 'src/app/shared/models/Integration-type-model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  private stateUrl = 'api/v1/integration';

  constructor(private httpRequest: HttpRequestService) { }

  fetchAllIntegrationTypes(): Observable<[]> {
    return this.httpRequest.get(this.stateUrl+'/integration-type-list');
  }
}
