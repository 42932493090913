import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { ClientModel } from 'src/app/shared/models/client-model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  private stateUrl = 'api/v1/manage-client';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchClients(): Observable<ClientModel[]> {
    return this.httpRequest.get(this.stateUrl + '/client-list');
  }

  addClient(clientModel: ClientModel): Observable<any> {
    return this.httpRequest.post(this.stateUrl + '/add-client',clientModel);
  }

  updateClient(clientModel: ClientModel): Observable<any> {
    return this.httpRequest.put(this.stateUrl + '/update-client',clientModel);
  }

  fetchClient(clientId:Number): Observable<ClientModel> {
    return this.httpRequest.get(this.stateUrl + '/'+clientId);
  }

}
