import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { ProjectIntegrationModel } from 'src/app/shared/models/project-integration-model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectIntegrationService {

  private stateUrl = 'api/v1/project-integration';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchProjectIntegration(): Observable<ProjectIntegrationModel[]> {
    return this.httpRequest.get(this.stateUrl + '/project-integration-list');
  }

  addProjectIntegration(projectIntegrationModel:ProjectIntegrationModel): Observable<ProjectIntegrationModel[]> {
    return this.httpRequest.post(this.stateUrl + '/add-project-integration',projectIntegrationModel);
  }
}
