import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { PermissionComponent } from './components/permission/permission.component';
import { PermissionAddEditComponent } from './components/permission-add-edit/permission-add-edit.component';
import { RoleComponent } from './components/role/role.component';
import { RoleAddEditComponent } from './components/role-add-edit/role-add-edit.component';
import { UserComponent } from './components/user/user.component';
import { UserAddEditComponent } from './components/user-add-edit/user-add-edit.component';
import { OrganizationComponent } from './components/organization/organization.component';
import { DepartmentComponent } from './components/department/department.component';
import { DepartmentAddEditComponent } from './components/department-add-edit/department-add-edit.component';
import { RecruiterComponent } from './components/recruiter/recruiter.component';
import { RecruiterAddEditComponent } from './components/recruiter-add-edit/recruiter-add-edit.component';
import { SSOGatewayComponent } from './components/sso-gateway/sso-gateway.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { AuthGuard } from './core/guards/auth.guard';
import { ClientAddEditComponent } from './components/client-add-edit/client-add-edit.component';
import { ClientComponent } from './components/client/client.component';
import { ProjectComponent } from './components/project/project.component';
import { ProjectAddEditComponent } from './components/project-add-edit/project-add-edit.component';
import { AddProjectIntegrationComponent } from './components/add-project-integration/add-project-integration.component';
import { ProjectIntegrationComponent } from './components/project-integration/project-integration.component';
import { AddManageReportComponent } from './components/add-manage-report/add-manage-report.component';
import { ManageReportComponent } from './components/manage-report/manage-report.component';
import { AddParameterComponent } from './shared/popups/popups';

const routes: Routes = [
  { path: '', redirectTo: '/organization', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'sso-gateway', component: SSOGatewayComponent },
  {
    path: '', component: HomeComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: [
      { path: '', component: DashboardComponent },
      { path: 'permission', component: PermissionComponent },
      { path: 'permission/new', component: PermissionAddEditComponent },
      { path: 'permission/:id', component: PermissionAddEditComponent },
      { path: 'role', component: RoleComponent },
      { path: 'role/new', component: RoleAddEditComponent },
      { path: 'role/:id', component: RoleAddEditComponent },
      { path: 'user', component: UserComponent },
      { path: 'user/new', component: UserAddEditComponent },
      { path: 'user/:id', component: UserAddEditComponent },
      { path: 'organization', component: OrganizationComponent },
      { path: 'department', component: DepartmentComponent },
      { path: 'department/new', component: DepartmentAddEditComponent },
      { path: 'department/:id', component: DepartmentAddEditComponent },
      { path: 'recruiter', component: RecruiterComponent },
      { path: 'recruiter/new', component: RecruiterAddEditComponent },
      { path: 'recruiter/:id', component: RecruiterAddEditComponent },
      { path: 'client', component: ClientComponent },
      { path: 'client/new', component: ClientAddEditComponent },
      { path: 'client/:id', component: ClientAddEditComponent },
      { path: 'project', component: ProjectComponent },
      { path: 'project/new', component: ProjectAddEditComponent },
      { path: 'project/:id', component: ProjectAddEditComponent },
      { path: 'project-integration', component: ProjectIntegrationComponent},
      { path: 'project-integration/new', component: AddProjectIntegrationComponent },
      {path:'manage-report/:new',component:AddManageReportComponent},
      {path:'manage-report',component:ManageReportComponent},
      {path:'manage-report/:id',component:AddManageReportComponent}
    
    ]
  },
  { path: '**', component: PageNotFoundComponent, canActivate: [AuthGuard] } // need to work on design
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes
    // { enableTracing: true } // <-- debugging purposes only
  )],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
