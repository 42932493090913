import { EmployeeLiteModel } from './employee-lite-model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClientModel, ClientFormBuilder } from './client-model';

export interface ProjectModel {
    id: number;
    name: string;
    description: string;
    employees: EmployeeLiteModel[];
    clientDTO : {
        id: number,
        name: string,
        description: string
    };
}

export class ProjectFormBuilder{

    projectModel : ProjectModel = {
        id: null,
        name: '',
        description: '',
        employees: [],
        clientDTO : {
            id : null,
            name: null,
            description: null
        }
    }

    projectForm: FormGroup = new FormGroup({
        id: new FormControl(this.projectModel.id,[Validators.required]),
        name: new FormControl(this.projectModel.name, [Validators.required]),
        description: new FormControl(this.projectModel.description, [Validators.required]),
        employees: new FormControl(this.projectModel.employees,[Validators.required]),
        clientDTO: new FormControl(this.projectModel.clientDTO,[Validators.required]),   
  });

    constructor() {
        this.projectForm.valueChanges.subscribe(val => {
            this.projectModel.id = val.id;
            this.projectModel.name = val.name;
            this.projectModel.description = val.description;
            this.projectModel.employees = val.employees;
            this.projectModel.clientDTO = val.clientDTO;
      });
    }

    setModelVals(res: ProjectModel) {
        this.projectForm.controls.id.setValue(res.id);
        this.projectForm.controls.name.setValue(res.name);
        this.projectForm.controls.description.setValue(res.description);
        this.projectForm.controls.employees.setValue(res.employees);
        this.projectForm.controls.clientDTO.setValue(res.clientDTO);
    }
 
     



}