import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { HelpService } from 'src/app/core/services/help.service';
import { ManageReportService } from 'src/app/core/services/manage-report.service';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { JasperParameterModel } from 'src/app/shared/models/jasper-parameter-model';

@Component({
  selector: 'app-manage-report',
  templateUrl: './manage-report.component.html',
  styleUrls: ['./manage-report.component.css']
})
export class ManageReportComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  displayedColumns = ['sid','id', 'name', 'moduleName', 'action'];
  
  dataSource: MatTableDataSource<JasperParameterModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  pageNo: number = 0;
  pageSize: number = 10;

  constructor(
    private manageReportService: ManageReportService,
    private messageService: MessageService,
    private router: Router,
 ) { }

    ngOnInit(): void {
      this.getReportDetails();
      this.dataSource = new MatTableDataSource<JasperParameterModel>([]);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    
    getReportDetails(){
      this.manageReportService.getReportDetails().subscribe((res: JasperParameterModel[]) => {
        this.dataSource = new MatTableDataSource<JasperParameterModel>(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    }
    deleteTagById(id: number) {
      this.manageReportService.deleteReportById(id).subscribe(res => {
        this.messageService.display('report deleted successfully! report ID : ' + id, 'OK', 'success');
        this.getReportDetails();
      });
    }
    routeToReportId(jasperParameterModel:JasperParameterModel,id:number) {
      this.router.navigate(['/manage-report',id]);
      this.manageReportService.routeToReportIds(jasperParameterModel);
    }
}
