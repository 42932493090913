import { Component, OnInit, ViewChild } from '@angular/core';
import { ClientModel } from 'src/app/shared/models/client-model';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ClientService } from 'src/app/core/services/client.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {

  displayedColumns = ['id', 'name', 'description','action'];
  dataSource: MatTableDataSource<ClientModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(
    private clientService:ClientService
  ) { }

  ngOnInit() {
     this.fetchClients();
     this.dataSource = new MatTableDataSource<ClientModel>([]);
     this.dataSource.paginator = this.paginator;
     this.dataSource.sort = this.sort;
  }

  fetchClients(){
     this.clientService.fetchClients().subscribe((res: ClientModel[]) => {
      this.dataSource = new MatTableDataSource<ClientModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}
