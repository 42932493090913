import { FormGroup, FormControl, Validators } from '@angular/forms';

export interface ProjectIntegrationModel {
    id: number;
    baseUrl: string;
    project:any;
    integrationType:any
}

export class ProjectIntegrationFormBuilder {

    projectIntegrationModel: ProjectIntegrationModel = {
        id: null,
        baseUrl: '',
        project: null,
        integrationType : null
    }

    projectIntegrationForm: FormGroup = new FormGroup({
        id: new FormControl(this.projectIntegrationModel.id, [Validators.required]),
        baseUrl: new FormControl(this.projectIntegrationModel.baseUrl, [Validators.required]),
        projects: new FormControl(this.projectIntegrationModel.project, [Validators.required]),
        integrationTypes: new FormControl(this.projectIntegrationModel.integrationType, [Validators.required])
    });

    constructor() {
        this.projectIntegrationForm.valueChanges.subscribe(val => {
            this.projectIntegrationModel.id = val.id;
            this.projectIntegrationModel.baseUrl = val.baseUrl;
            this.projectIntegrationModel.project = val.projects;
            this.projectIntegrationModel.integrationType = val.integrationTypes
        });
    }

    setModelVals(res: ProjectIntegrationModel) {
        this.projectIntegrationForm.controls.id.setValue(res.id);
        this.projectIntegrationForm.controls.baseUrl.setValue(res.baseUrl);
        this.projectIntegrationForm.controls.projects.setValue(res.project);
        this.projectIntegrationForm.controls.integrationTypes.setValue(res.integrationType);
    }





}