import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectModel } from 'src/app/shared/models/project-model';
import { ProjectService } from 'src/app/core/services/project.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {

  displayedColumns = ['id', 'name', 'description', 'employees', 'client' ,'action'];
  dataSource: MatTableDataSource<ProjectModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private projectService:ProjectService
  ) { }

  ngOnInit() {
    this.fetchProjects();
    this.dataSource = new MatTableDataSource<ProjectModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchProjects() {
    this.projectService.fetchProjects().subscribe((res: ProjectModel[]) => {
      this.dataSource = new MatTableDataSource<ProjectModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

}
