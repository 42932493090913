import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { Observable } from 'rxjs';
import { EmployeeModel } from '../../shared/models/employee-model';
import { EmployeeDocumentModel } from 'src/app/shared/models/employee-documents-model';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  private stateUrl = 'api/v1/employee';

  constructor(private httpRequest: HttpRequestService) { }

  fetchEmployeesCount(): Observable<number> {
    return this.httpRequest.get(this.stateUrl + '/count');
  }

  fetchEmployees(pageNo, pageSize): Observable<EmployeeModel[]> {
    return this.httpRequest.get(this.stateUrl + '?page=' + pageNo + '&size=' + pageSize);
  }

  fetchEmployee(employeeId): Observable<EmployeeModel> {
    return this.httpRequest.get(this.stateUrl + '/' + employeeId + '?withDetail=true');
  }
  
  fetchEmployeeDocuments(employeeId): Observable<EmployeeDocumentModel> {
    return this.httpRequest.get('api/v1/employeeDocument' + '/' + employeeId + '?withDetail=true');
  }

  updateEmployee(employeeModel: FormData): Observable<EmployeeModel> {
    return this.httpRequest.filePut(this.stateUrl, employeeModel);
  }

  fetchMyProfile(): Observable<any> { // needs to update place
    return this.httpRequest.get(this.stateUrl + '/view-profile');
  }

  uploadEmployeeProfilePic(employeeId: number, employeeProfilePic: FormData): Observable<any> {
    return this.httpRequest.filePut(this.stateUrl + '/profile/' + employeeId, employeeProfilePic);
  }

  fetchEmpProfilePic(employeeId: number): Observable<any> {
    return this.httpRequest.get(this.stateUrl + '/profile/photo/' + employeeId);
  }

  fetchEmpProfilePicByUserId(employeeId: number): Observable<any> {
    return this.httpRequest.get(this.stateUrl + '/profile/photo/user');
  }

  fetchEmployeeStats(): Observable<any> {
    return this.httpRequest.get(this.stateUrl + '/stats');
  }

  fetchEmployeeSearchCountFilter(searchFormFields): Observable<any> {
    return this.httpRequest.post(this.stateUrl + '/count/filter', searchFormFields);
  }

  fetchEmployeeSearchFilter(searchFormFields, pageNo: number, pageSize: number): Observable<any> {
    return this.httpRequest.post(this.stateUrl + '/filter?page=' + pageNo + '&size=' + pageSize, searchFormFields);
  }

  fetchEmployeesLite(): Observable<EmployeeModel[]> {
    return this.httpRequest.get(this.stateUrl + '/lite');
  }
  
  
}
