import { Component, OnInit } from '@angular/core';
import { AddParameterComponent } from 'src/app/shared/popups/popups';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JasperReportSectionParameter, ManageReportSection, ManageReportService } from 'src/app/core/services/manage-report.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Form, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { LoaderService } from 'src/app/shared/factory/loader.service';
import { FormFields, JasperParameterModel } from 'src/app/shared/models/jasper-parameter-model';
import { isThursday } from 'date-fns';
import { Location } from '@angular/common';

@Component({
  selector: 'app-add-manage-report',
  templateUrl: './add-manage-report.component.html',
  styleUrls: ['./add-manage-report.component.css']
})
export class AddManageReportComponent implements OnInit {
  jasperForm: FormGroup;
  editIndex: number;
  saveFilds = [];
  isEdit: boolean = false;
  ModulesdataAll = [];
  displayedColumns = ['id', 'name', 'jasperReportId', 'type',];

  dataSource = new MatTableDataSource<FormFields>();
  isVisible = [false];
  id: number;
  selectedFileName = "";
  jasperReportFile: any = null;


  constructor(private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private manageReportService: ManageReportService,
    private messageService: MessageService,
    private routes: Router,
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private location:Location
    ) {
    this.jasperForm = this.formBuilder.group({
      name: [''],
      description: [''],
      folderPath: [''],
      moduleName: [''],
      formFields: this.formBuilder.array([
      ])
    });
  }

  ngOnInit(): void {
    if (this.route.snapshot.params['new'] !== 'new') {
      this.isEdit = true;

      this.manageReportService.manageReportFieldDetails.subscribe((manageReportDetails: JasperParameterModel) => {
        console.log(manageReportDetails);

        const formFieldsDetails: FormFields[] = [];
        manageReportDetails.formFields.forEach(element => {
          const formFieldsDetail: FormFields = {};
          formFieldsDetail.jasperReportId = element.jasperReportId;
          formFieldsDetail.name = element.name;
          formFieldsDetail.type = element.type;

          formFieldsDetails.push(formFieldsDetail);
        });
        this.jasperForm.patchValue({
          "name": manageReportDetails.name,
          "description": manageReportDetails.description,
          "folderPathame": manageReportDetails.folderPath,
          "moduleName": manageReportDetails.moduleName
        });
        this.id = manageReportDetails.id;
        this.dataSource.data = formFieldsDetails;
      });
    }
  }
  getFormFields() {
    let index = this.route.snapshot.params['new'];
  }

  Fields(): FormArray {
    return this.jasperForm.get("formFields") as FormArray;
  }
  newFields(index): FormGroup {
    let i = this.saveFilds.length - 1;
    return this.formBuilder.group({

      name: [this.saveFilds[i]?.name],
      jasperReportId: [this.saveFilds[i]?.jasperReportId],
      type: [this.saveFilds[i]?.type],
    });
  }
  addFields(index) {
    this.Fields().push(this.newFields(index));
    this.isVisible.push(false)
  }

  deleteFields(i: number) {
    this.saveFilds.splice(i, 1);
    // this.selectValue.splice(i, 1);
    this.Fields().removeAt(i);
  }

  addFieldsToSection() {
    const reportSection: ManageReportSection = {};
    const reportSectionParameterMaster: JasperReportSectionParameter[] = [];
    reportSection.name = this.jasperForm.value.name
    reportSection.description = this.jasperForm.value.description;
    reportSection.moduleName = this.jasperForm.value.moduleName;

    this.jasperForm.value.formFields.forEach(v => {

      const reportSectionFields: JasperReportSectionParameter = {};
      reportSectionFields.jasperReportId = v.jaspaReportId;
      reportSectionFields.name = v.name;
      reportSectionFields.type = v.type;
      reportSectionParameterMaster.push(reportSectionFields);

    });

    reportSection.parameter = reportSectionParameterMaster;
    let formData: FormData = new FormData();
    var dto = new Blob([JSON.stringify(this.jasperForm.value)], {
      type: 'application/json'
    });
    formData.append('dto', dto);
    if (this.jasperReportFile !== null) formData.append('file', this.jasperReportFile, this.jasperReportFile.name);

    this.manageReportService.addJasperReportData(formData).subscribe((res) => {
      console.log(res);
      this.messageService.display('jaser report details added successfully! : ', 'OK', 'success');
      this.routes.navigate(['/manage-report']);
    })
  }

  addParameterDialog(index, rowId?) {
    console.log(this.isEdit);
    this.editIndex = index;
    const dialogRef = this.dialog.open(AddParameterComponent, {
      disableClose: false,
      width: '50%',
      data: { dataSource: this.dataSource[index], isEdit: this.isEdit }
    });
    dialogRef.afterClosed().subscribe(result => {

      let formFieldsDetails: FormFields = {};
      if (result?.fieldsForm) {
        this.saveFilds.push(result.fieldsForm);

        if (result.fieldsForm.values == undefined) {
          result.fieldsForm.values = [];
        }

        formFieldsDetails = result.fieldsForm;

        this.addFields(index);
        this.loaderService.updateInboxAction(true);
        let temp = [];
        temp = this.dataSource.data;
        temp.push(formFieldsDetails);

        this.dataSource.data = temp;
        console.log(this.dataSource);
      }
    });
  }

  AddFileInput($event) {
    var regex = /(.*?)\.(zip)$/;
    if (($event.srcElement.files.length > 0) && (regex.test($event.srcElement.files[0].name))) {
      this.selectedFileName = $event.srcElement.files[0].name;
      this.jasperReportFile = $event.srcElement.files[0];
    }
  }
}
