import { Injectable } from '@angular/core';
import { HttpRequestService } from '../http/http-request.service';
import { ProjectModel } from 'src/app/shared/models/project-model';
import { Observable } from 'rxjs';
import { ProjectLiteModel } from 'src/app/shared/models/project-lite-model';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private stateUrl = 'api/v1/manage-project';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  fetchProjects(): Observable<ProjectModel[]> {
    return this.httpRequest.get(this.stateUrl + '/project-list');
  }

  addProject(projectModel:ProjectModel): Observable<ProjectModel[]> {
    return this.httpRequest.post(this.stateUrl + '/add-project',projectModel);
  }

  updateProject(projectModel:ProjectModel): Observable<ProjectModel[]> {
    return this.httpRequest.put(this.stateUrl + '/update-project',projectModel);
  }

  fetchProject(projectId:Number): Observable<ProjectModel> {
    return this.httpRequest.get(this.stateUrl + '/'+projectId);
  }

  fetchLiteProjects(): Observable<ProjectLiteModel[]> {
    return this.httpRequest.get(this.stateUrl + '/project-lite-list');
  }
}
