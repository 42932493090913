import { Component, OnInit } from '@angular/core';
import { ProjectFormBuilder, ProjectModel } from 'src/app/shared/models/project-model';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EmployeeService } from 'src/app/core/services/employee.service';
import { EmployeeLiteModel } from 'src/app/shared/models/employee-lite-model';
import {Location} from '@angular/common';
import { ProjectService } from 'src/app/core/services/project.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { ClientService } from 'src/app/core/services/client.service';
import { ClientModel } from 'src/app/shared/models/client-model';

@Component({
  selector: 'app-project-add-edit',
  templateUrl: './project-add-edit.component.html',
  styleUrls: ['./project-add-edit.component.css']
})
export class ProjectAddEditComponent implements OnInit {

    project: ProjectFormBuilder = new ProjectFormBuilder();
    projectForm: FormGroup = this.project.projectForm;
    projectModel: ProjectModel = this.project.projectModel;
    addUpdateText = '';
    employees = this.projectModel.employees;
    clients = []

    compareFn(obj1: ProjectModel, obj2: ProjectModel) {
      return obj1 && obj2 ? obj1.id === obj2.id : obj1 === obj2;
    }
  
    constructor(
      private route: ActivatedRoute,
      private router: Router,
      private employeeService:EmployeeService,
      public location: Location,
      private projectService: ProjectService,
      private messageService: MessageService,
      private clientService : ClientService
    ) { }

    ngOnInit() {
      this.fetchEmployees();
      this.fetchClients();
      this.projectModel.id = parseInt(this.route.snapshot.paramMap.get('id'), 0);
      this.addUpdateText = this.projectModel.id ? 'Update' : 'Add';
      this.projectModel.id ? this.fetchProject() : null;
    }

    fetchClients(){
      this.clientService.fetchClients().subscribe((res: ClientModel[]) => {
         this.clients = res;
     }); 
    }

    fetchProject(){
         this.projectService.fetchProject(this.projectModel.id).subscribe((res: ProjectModel) => {
         this.project.setModelVals(res);
      }); 
    }

    fetchEmployees(){
      this.employeeService.fetchEmployeesLite().subscribe((res: EmployeeLiteModel[]) => {
        this.employees = res;
      });
    }

    addUpdateProject(){
      if(this.projectModel.id){
        this.projectService.updateProject(this.projectModel).subscribe((res) => {
          this.messageService.display('Project updated is successfull! Project ID : ' + this.projectModel.id, 'OK', 'success');
          this.router.navigate(['/project']);
       });
      }
      else{
        this.projectModel.id = null;
        this.projectService.addProject(this.projectModel).subscribe((res) => {
          this.messageService.display('Project added is successfull! Project ID : ' + res, 'OK', 'success');
          this.router.navigate(['/project']);
       });
     }
   }

}
