import { Component, OnInit } from '@angular/core';
import { ProjectIntegrationFormBuilder, ProjectIntegrationModel } from 'src/app/shared/models/project-integration-model';
import { FormGroup } from '@angular/forms';
import { ProjectService } from 'src/app/core/services/project.service';
import { ProjectIntegrationService } from 'src/app/core/services/project-integration.service';
import { IntegrationService } from 'src/app/core/services/integration.service';
import { Location } from '@angular/common';
import { MessageService } from 'src/app/shared/factory/message.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-project-integration',
  templateUrl: './add-project-integration.component.html',
  styleUrls: ['./add-project-integration.component.css']
})
export class AddProjectIntegrationComponent implements OnInit {

  projectIntegration : ProjectIntegrationFormBuilder = new ProjectIntegrationFormBuilder();
  projectIntegrationForm: FormGroup = this.projectIntegration.projectIntegrationForm;
  projectIntegrationModel: ProjectIntegrationModel = this.projectIntegration.projectIntegrationModel;
  projects = [];
  integrationTypes = [];

  constructor(
    private projectService: ProjectService,
    private projectIntegrationService: ProjectIntegrationService,
    private integrationService: IntegrationService,
    private location: Location,
    private messageService: MessageService,
    private router: Router
  ) { }

  ngOnInit() {
    this.fetchProjects();
    this.fetchIntegrationTypes();
  }

  fetchProjects(){
    this.projectService.fetchLiteProjects().subscribe((res) => {
      this.projects = res;
    });
  }

  fetchIntegrationTypes(){
    this.integrationService.fetchAllIntegrationTypes().subscribe((res) => {
     this.integrationTypes = res;
      })
    }

    addProjectIntegration(){
      this.projectIntegrationService.addProjectIntegration(this.projectIntegrationModel).subscribe((res) => {
        this.messageService.display('Project Integrated successfull! Project Integration ID : ' + res, 'OK', 'success');
        this.router.navigate(['/project-integration']);
      })
    }

  
 
}
