import { Component, OnInit } from '@angular/core';
import { ClientFormBuilder, ClientModel } from 'src/app/shared/models/client-model';
import { FormGroup } from '@angular/forms';
import { ClientService } from 'src/app/core/services/client.service';
import { MessageService } from 'src/app/shared/factory/message.service';
import { Router, ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-client-add-edit',
  templateUrl: './client-add-edit.component.html',
  styleUrls: ['./client-add-edit.component.css']
})
export class ClientAddEditComponent implements OnInit {


  client: ClientFormBuilder = new ClientFormBuilder();
  clientModel: ClientModel = this.client.clientModel;
  clientForm: FormGroup = this.client.clientForm;

  constructor(
    private clientService: ClientService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    public location: Location
  ) { }

  ngOnInit() {
    this.clientModel.id = parseInt(this.route.snapshot.paramMap.get('id'), 0);
    this.clientModel.id ? this.clientModel.id : null;
    this.clientModel.id ? this.fetchClient() : null;
  }

  fetchClient(){
    this.clientService.fetchClient(this.clientModel.id).subscribe((res: ClientModel) => {
    this.client.setModelVals(res);
   }); 
  }

  addUpdateClient(){
      if(this.clientModel.id){
        this.clientService.updateClient(this.clientModel).subscribe((res) => {
          this.messageService.display('Client updated is successfull! Client ID : ' + this.clientModel.id, 'OK', 'success');
          this.router.navigate(['/client']);
        });
      }
      else{
        this.clientModel.id = null;
        this.clientService.addClient(this.clientModel).subscribe((res) => {
          this.messageService.display('Client added is successfull! Client ID : ' + res, 'OK', 'success');
          this.router.navigate(['/client']);
        });
     }
   }

}
