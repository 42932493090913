import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectIntegrationModel } from 'src/app/shared/models/project-integration-model';
import { ProjectIntegrationService } from 'src/app/core/services/project-integration.service';

@Component({
  selector: 'app-project-integration',
  templateUrl: './project-integration.component.html',
  styleUrls: ['./project-integration.component.css']
})
export class ProjectIntegrationComponent implements OnInit {

  displayedColumns = ['id', 'projectName', 'integretionType', 'baseUrl'];
  dataSource: MatTableDataSource<ProjectIntegrationModel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
     private projectIntegrationService:ProjectIntegrationService
    ) { }

  ngOnInit() {

    this.fetchProjectIntegrations();
    this.dataSource = new MatTableDataSource<ProjectIntegrationModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  fetchProjectIntegrations(){
     this.projectIntegrationService.fetchProjectIntegration().subscribe((res: ProjectIntegrationModel[]) => {
      this.dataSource = new MatTableDataSource<ProjectIntegrationModel>(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

}
