import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { SSOGatewayComponent } from './sso-gateway/sso-gateway.component';
import { MaterialModule } from '../shared/modules/material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';  // <-- #1 import module
import { LoginService } from '../core/services/login.service';
import { SSOGatewayService } from '../core/services/sso-gateway.service';
import { AppRoutingModule } from '../app-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { PermissionComponent } from './permission/permission.component';
import { PermissionAddEditComponent } from './permission-add-edit/permission-add-edit.component';
import { RoleComponent } from './role/role.component';
import { RoleAddEditComponent } from './role-add-edit/role-add-edit.component';
import { UserComponent } from './user/user.component';
import { UserAddEditComponent } from './user-add-edit/user-add-edit.component';
import { OrganizationComponent } from './organization/organization.component';
import { DepartmentComponent } from './department/department.component';
import { DepartmentAddEditComponent } from './department-add-edit/department-add-edit.component';
import { RecruiterComponent } from './recruiter/recruiter.component';
import { RecruiterAddEditComponent } from './recruiter-add-edit/recruiter-add-edit.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { NgxPermissionsModule } from 'ngx-permissions';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { CalendarModule } from 'angular-calendar';
import { ClientAddEditComponent } from './client-add-edit/client-add-edit.component';
import { ClientComponent } from './client/client.component';
import { ProjectAddEditComponent } from './project-add-edit/project-add-edit.component';
import { ProjectComponent } from './project/project.component';
import { AddProjectIntegrationComponent } from './add-project-integration/add-project-integration.component';
import { ProjectIntegrationComponent } from './project-integration/project-integration.component';
import { AddManageReportComponent } from './add-manage-report/add-manage-report.component';
import { ManageReportComponent } from './manage-report/manage-report.component';
import { AddParameterComponent } from '../shared/popups/popups';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    OwlDateTimeModule,
    // AddParameterComponent,
    OwlNativeDateTimeModule,
    CalendarModule,
    NgxPermissionsModule.forRoot()
  ],
  providers: [
    LoginService,
    SSOGatewayService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true, disableClose: false } },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 10000 } }
  ],
  declarations: [
    LoginComponent,
    SSOGatewayComponent,
    DashboardComponent,
    HomeComponent,
    PermissionComponent,
    PermissionAddEditComponent,
    RoleComponent,
    RoleAddEditComponent,
    UserComponent,
    UserAddEditComponent,
    AddParameterComponent,
    OrganizationComponent,
    DepartmentComponent,
    DepartmentAddEditComponent,
    RecruiterComponent,
    RecruiterAddEditComponent,
    ClientAddEditComponent,
    ClientComponent,
    ProjectAddEditComponent,
    ProjectComponent,
    AddProjectIntegrationComponent,
    ProjectIntegrationComponent,
    AddManageReportComponent,
    ManageReportComponent,
  
  ],
  entryComponents: [
    AddParameterComponent
  ]
})
export class ComponentsModule { }
